import { useMemo } from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import Typography from '@mui/material/Typography';
import { QuoteModel } from '@model/quote.model';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { transformTitleId } from '@utils/functions/utils';
import { parseHTML } from '@utils/functions/parseHTML';
import quotationImg from '../../../../public/assets/images/quote.svg';
import styles from './QuoteParagraph.module.scss';

const Box = dynamic(() => import('@mui/material/Box'));
const Grid = dynamic(() => import('@mui/material/Grid'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const BackgroundTransitionBox = dynamic(() => import('@atoms/BackgroundTransitionBox/BackgroundTransitionBox'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const FadeIn = dynamic(() => import('@atoms/FadeIn'));
const RichText = dynamic(() => import('@organisms/RichTextParagraph/RichText'));

export const QuoteParagraph = ({ fieldParagraph }: FieldParagraphProps) => {
  const data = useMemo(() => {
    const { text, author, authorTitle, field_side_description, behavior_settings } = fieldParagraph || {};
    const newData: QuoteModel = {
      quoteText: text?.value || '',
      sideText: field_side_description?.value || '',
      author,
      authorTitle,
      anchorData: behavior_settings?.style_options?.toc?.label || '',
      verticalLinesDirection: behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
      backgroundTransition: behavior_settings?.style_options?.transition?.css_class,
      isQuotation: behavior_settings?.style_options?.quote_styles?.css_class == 'author-on',
    };

    return newData;
  }, [fieldParagraph]);

  const {
    backgroundTransition,
    quoteText,
    sideText,
    author,
    authorTitle,
    anchorData,
    verticalLinesDirection,
    isQuotation,
  } = data || {};

  const isSplit = useMemo(() => !!sideText, [sideText]);

  const quoteContainerClasser = useMemo(
    () =>
      `${styles.quoteContainer} ${isQuotation ? styles.quotation : styles.highlightedText} ${
        isSplit ? styles.splitted : ''
      }`,
    [isQuotation, isSplit]
  );

  if (!data) return null;

  return (
    <BackgroundTransitionBox
      anchor-data={transformTitleId(anchorData || '')}
      transition={backgroundTransition}
      colorVariant="light"
      linesDirection={verticalLinesDirection}
    >
      <FadeIn>
        <GridContainer className={styles.QuoteParagraph}>
          <Row alignItems="center" justifyContent="center">
            {isSplit && (
              <>
                <Grid item xs={1} lg={0} />
                <Grid
                  item
                  xs={12}
                  lg={5}
                  sx={{
                    '& :is(h2,h3,h4,h5,h6):first-of-type ': {
                      mt: 0,
                    },
                  }}
                >
                  <RichText text={sideText} />
                </Grid>
              </>
            )}
            <Grid item xs={10} lg={isSplit ? 6 : 10} display="flex" justifyContent="center">
              <Box className={quoteContainerClasser}>
                {isQuotation && (
                  <Box width={96} height={80} position="absolute" top={48}>
                    <Image src={quotationImg.src} alt="quote-img" fill style={{ objectFit: 'cover' }}></Image>
                  </Box>
                )}
                <Typography component="div" variant={isQuotation ? 'h6Mono' : 'h5'}>
                  {parseHTML(quoteText)}
                </Typography>
                {!!author && (
                  <Box component="div">
                    <Typography component="span" variant="bodyS" fontWeight={700} display="inline-block" mt={16}>
                      {author}
                    </Typography>
                    {!!authorTitle && (
                      <Typography component="span" variant="bodyS" display="inline-block" mt={16}>
                        ,&nbsp;{authorTitle}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Row>
        </GridContainer>
      </FadeIn>
    </BackgroundTransitionBox>
  );
};

export default QuoteParagraph;
